



const Serverurl= ["https://mmcoachingcenter.com/appfiles/"]
//const Serverurl= ["http://localhost/xampp/mmcoaching/"]


export default Serverurl;






