import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Serverurl from './Apiurl';
import axios from 'axios';
import NotificationPopUp from './NotificationPopUp';
import "react-big-calendar/lib/css/react-big-calendar.css";
import notification from './images/notification-icon.png';
import { styled } from '@mui/material/styles';


moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export default class ReactBigCalendar extends Component 
{
constructor(props) 
	  {
			super();
			
			this.state = {eventsData:[],popuptype:"1",noid:0,celldate:'',edate:''}
			  this.updateDonenewParent = this.updateDonenewParent;
	  }
	  		componentWillMount() { 
		
localStorage.setItem("pageactive",7)
}
 handleSelect=(date)=>{
	console.log(date);
 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
  
  date1=date.getFullYear()+"-"+month+"-"+date1
	 this.setState({ noid: 0 ,celldate:date1,edate:date});  
 /*   const title = window.prompt("New Event name");

     const updatedArray = [...this.state.eventsData];
	 updatedArray.title=title;
	 	 updatedArray.start=start;
		 updatedArray.end=end;
	 this.setState({eventsData:updatedArray});*/
	  setTimeout(() => this.refs.NotificationPopUp.handleClickOpen(),500);
 }
  handleNotification=(start,noid)=>{
	
	   this.setState({ noid: noid });  
	   setTimeout(() => this.refs.NotificationPopUp.handleClickOpen(),500);
  }
  updateDonenewParent = (text) => { this.NotificationAPIcall();  }
 componentDidMount() {
	 	document.title = "MM Coching Center - Notifications";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	 this.NotificationAPIcall();
	 
 }
NotificationAPIcall()
{let struserid="";
	if(localStorage.getItem("MMCrole")!=="1")
	 
		  { struserid=localStorage.getItem("MMCuserid");
		  }
axios.get(Serverurl+'ajaxnotifications.php?action=list&no_type=1&userid='+struserid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({eventsData:resp.data});
			}
	   });
}

customDayPropGetter = (date) => {

  let data = this.state.eventsData
  
   var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
  
  date=date1+"/"+month+"/"+date.getFullYear()
   var obj = data.find((item, index) => {
	  
	   
	   
	   
	     if ((item.nodate === date && item.title !== "")  )
		 {
			 
			   return data[index]
		 }
   });
  
    if(obj !== undefined && obj.nodate === date &&  obj.title !== "")
	{
	 
        return { style: { backgroundColor: "#2588f5", Color:'#fff' } }
	}
    
        else {return { style: { backgroundColor: "#fff", Color:'#fff' } }}
   
	/*return {
	  style: {
		backgroundColor: '#fff', //this works
		color: '#ffffff' //but why doesn't this work?
	  }
	}*/
}

 eventStyleGetter=(event,start, end,isSelected)=>{

    console.log(event);
    var backgroundColor = '#2588f5' ;
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: '#fff',
        border: '0px',
        display: 'block',
		textalign:'Center',
    };
    return {
        style: style
    };
}
render() {
  return (
    <div className="pagebox-col"> 
	<div className="boxheading">
	  <div className="contntpad">
	  <h2><img src={notification} /> Notifications</h2>
	  </div> </div>
	
	<div className="contntpad">
	
	
<NotificationPopUp ref="NotificationPopUp" id={this.state.noid} celldate={this.state.celldate} edate={this.state.edate} notype={this.state.popuptype} updateDonenew={this.updateDonenewParent}/>

      <Calendar
        views={["month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
		dayPropGetter={this.customDayPropGetter }
        events={this.state.eventsData}
        style={{ height: "140vh" }}
        onSelectEvent={(event) => this.handleNotification(event.start,event.id)}
		 eventPropGetter={(this.eventStyleGetter)}
        onSelectSlot={(event)=>this.handleSelect(event.start)}
      /> </div>
    </div>
  );
}
}
